<template>
  <ion-page class="page bg-transparent">
    <div class="content">
      <SearchBar @search="handleSearch" :defaultValue="text" :clearValue="false" class="mr-1 search-bar-wrp" />

      <search-tabs class="my-2" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />

      <div v-if="loading" class="w-100 d-flex justify-content-center">
        <ChLoading size="lg" />
      </div>
      <div v-else>
        <div v-if="isSearchText" class="results">
          <div id="local-top" />
          <infinite-search
            :current-tab="currentTab"
            :characters="characters"
            :blabs="blabs"
            :worlds="worlds"
            :spaces="spaces"
            :chatrooms="chatrooms"
            :mrktplace="marketplace"
            :users="users"
            :tags="tags"
            :paging="charactersPaging"
            :wrldpaging="worldsPaging"
            :spacespaging="spacesPaging"
            :chatroomspaging="chatroomsPaging"
            :blabpaging="blabsPaging"
            :mrktpaging="marketplaceListingsPaging"
            :userpaging="usersPaging"
            :tagpaging="tagsPaging"
            @load="requestLoadMoreCharacters"
            @load-worlds="requestLoadMoreWorlds"
            @load-chatrooms="requestLoadMoreChatrooms"
            @load-spaces="requestLoadMoreSpaces"
            @load-blabs="requestLoadMoreBlabs"
            @load-mrktplace="requestLoadMoreMrktplaceListings"
            @load-users="requestLoadMoreUsers"
            @load-tags="requestLoadMoreTags"
            @search-tag="searchByTag"
          />
          <div v-if="currentTab === 'all'" id="search-results-page-wrapper">
            <all-search-card
              :characters="characters"
              :blabs="blabs"
              :spaces="spaces"
              :worlds="worlds"
              :chatrooms="chatrooms"
              :marketplace="marketplace"
              :users="users"
              :tags="tags"
              :is-tag-search="text?.includes('#')"
              @change-tab="changeTab"
              @search-tag="searchByTag"
            />
          </div>
        </div>
        <div v-else class="no-data">No search results found</div>
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import SearchTabs from './components/SearchTabs.vue';
import InfiniteSearch from './components/InfiniteSearch.vue';
import allSearchCard from './components/AllSearchCard.vue';
import { Paging, Tab } from '@/shared/types/static-types';
import { searchTabs } from '@/shared/statics/tabs';
import { getPosts } from '@/shared/actions/blabs';
import { getWorldListings } from '@/shared/actions/worlds';
import { getMarketplaceListings } from '@/shared/actions/marketplace';
import { getCharacters } from '@/shared/actions/characters';
import { getUsers } from '@/shared/actions/users';
import { toast } from '@/shared/native';
import { getTags } from '@/shared/actions/tags';
import { sendAnalyticsEvent } from '@/shared/services/analytics';
import { searchChatRooms } from '@/shared/actions/socialSpaceChatroom';
import { authStore } from '@/shared/pinia-store/auth';

import SearchBar from '@/shared/components/nav-bar/SearchBar.vue';
import { getNextPage } from '@/shared/helpers/pagination';

@Options({
  name: 'SearchPage',
  components: {
    SearchTabs,
    InfiniteSearch,
    SearchBar,
    allSearchCard,
  },
})
export default class SearchPage extends Vue {
  public tabs = searchTabs;
  public currentTab: string = 'all';
  public tabIndex = 0;
  public characters: any = [];
  public worlds = [];
  public spaces = [];
  public blabs = [];
  public marketplace = [];
  public users = [];
  public tags = [];
  public chatrooms = [];
  public text: string | null = null;
  public loading = false;

  public hashtext = '';
  public charactersPaging: Paging | null = null;
  public worldsPaging: Paging | null = null;
  public blabsPaging: Paging | null = null;
  public spacesPaging: Paging | null = null;
  public chatroomsPaging: Paging | null = null;
  public marketplaceListingsPaging: Paging | null = null;
  public usersPaging: Paging | null = null;
  public tagsPaging: Paging | null = null;
  public isSearchText = false;
  public isSearchingTag = false;

  public get windowWidth() {
    const { width } = useWindowSize();
    return width.value;
  }

  isEmpty = isEmpty;

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  public get getTerm() {
    const router = useRouter();
    return router.currentRoute.value.query.term;
  }

  public changeTagName() {
    const tIndex = indexOf(
      this.tabs,
      find(this.tabs, (tab) => tab.value === 'tags')
    );

    if (this.text?.includes('#')) {
      this.tabs[tIndex].name = 'Similar Tags';
    } else {
      this.tabs[tIndex].name = 'Tags';
    }
  }

  public async searchCharTags() {
    if (!isEmpty(this.text) && this.isSearchingTag) {
      this.isSearchText = true;
      try {
        const { results, ...paging } = await getCharacters({ page: 1, page_size: 5, tags: this.hashtext });
        this.characters = results;
        this.charactersPaging = paging;
      } catch (e) {
        await toast.show(`Couldn't load Search `, 'nonative', 'danger');
      }
    } else {
      this.isSearchText = false;
    }
  }

  public async searchBlabTags() {
    if (!isEmpty(this.text) && this.isSearchingTag) {
      this.isSearchText = true;
      try {
        const { results, ...paging } = await getPosts(1, 5, { tags: this.hashtext });
        this.blabs = results;
        this.blabsPaging = paging;
      } catch (e) {
        await toast.show(`Couldn't load Search `, 'nonative', 'danger');
      }
    } else {
      this.isSearchText = false;
    }
  }

  public async searchWorldTags() {
    if (!isEmpty(this.text) && this.isSearchingTag) {
      this.isSearchText = true;
      try {
        const { results, ...paging } = await getWorldListings(1, 5, 'story', { tags: this.hashtext });
        this.worlds = results;
        this.worldsPaging = paging;
      } catch (e) {
        await toast.show(`Couldn't load Search `, 'nonative', 'danger');
      }
    } else {
      this.isSearchText = false;
    }
  }

  public async searchChatroomTags() {
    if (!isEmpty(this.text) && this.isSearchingTag) {
      this.isSearchText = true;
      try {
        const { results, ...paging } = await searchChatRooms(1, 5, { tags: this.hashtext });
        this.chatrooms = results;
        this.chatroomsPaging = paging;
      } catch (e) {
        await toast.show(`Couldn't load Search `, 'nonative', 'danger');
      }
    } else {
      this.isSearchText = false;
    }
  }

  public async searchSpacesTags() {
    if (!isEmpty(this.text) && this.isSearchingTag) {
      this.isSearchText = true;
      try {
        const { results, ...paging } = await getWorldListings(1, 5, 'socialspace', { tags: this.hashtext });
        this.spaces = results;
        this.spacesPaging = paging;
      } catch (e) {
        await toast.show(`Couldn't load Search `, 'nonative', 'danger');
      }
    } else {
      this.isSearchText = false;
    }
  }

  public async searchMarketplaceTags() {
    if (!isEmpty(this.text) && this.isSearchingTag) {
      this.isSearchText = true;
      try {
        const { results, ...paging } = await getMarketplaceListings(1, 5, { tags: this.hashtext });
        this.marketplace = results;
        this.marketplaceListingsPaging = paging;
      } catch (e) {
        await toast.show(`Couldn't load Search `, 'nonative', 'danger');
      }
    } else {
      this.isSearchText = false;
    }
  }

  public tabChanged({ value }: Tab) {
    const router = useRouter();
    this.currentTab = value;
    this.tabIndex = indexOf(
      this.tabs,
      find(this.tabs, (tab) => tab.value === value)
    );

    router.replace({ name: 'search', query: { ...router.currentRoute.value.query, tab: value } });
  }

  public changeTab({ value }: Tab) {
    const tIndex = indexOf(
      this.tabs,
      find(this.tabs, (tab) => tab.value === value)
    );
    if (tIndex !== -1) {
      this.tabIndex = tIndex;
      this.currentTab = value;
    }
  }

  public searchByTag({ value }: Tab, text: any) {
    const router = useRouter();
    const nameIndex = indexOf(
      this.tabs,
      find(this.tabs, (tab) => tab.value === value)
    );

    this.text = `#${text}`;

    router.push({ name: 'search', query: { ...router.currentRoute.value.query, term: this.text } });
    this.tabs[nameIndex].name = 'Similar Tags';

    this.search();

    setTimeout(() => this.changeTab({ value: 'all' } as Tab), 500);
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 550;
  }

  public async searchcharacters() {
    if (this.text) {
      this.isSearchText = true;
      try {
        const { results, ...paging } = await getCharacters({ page: 1, page_size: 5, search: this.text });
        this.characters = results;
        this.charactersPaging = paging;
      } catch (e) {
        await toast.show(`Couldn't load Search `, 'nonative', 'danger');
      }
    } else {
      this.isSearchText = false;
    }
  }

  public async requestLoadMoreCharacters() {
    try {
      const { results, ...paging } = await getNextPage(this.charactersPaging!);
      this.characters = this.characters.concat(results);
      this.charactersPaging = paging;
    } catch (e) {}
  }

  public async requestLoadMoreWorlds() {
    try {
      const { results, ...paging } = await getNextPage(this.worldsPaging!);
      this.worlds = this.worlds.concat(results);
      this.worldsPaging = paging;
    } catch (e) {}
  }

  public async requestLoadMoreChatrooms() {
    try {
      const { results, ...paging } = await getNextPage(this.chatroomsPaging!);
      this.chatrooms = this.chatrooms.concat(results);
      this.chatroomsPaging = paging;
    } catch (e) {}
  }

  public async requestLoadMoreSpaces() {
    try {
      const { results, ...paging } = await getNextPage(this.spacesPaging!);
      this.spaces = this.spaces.concat(results);
      this.spacesPaging = paging;
    } catch (e) {}
  }

  public goBack() {
    const router = useRouter();
    router.go(-1);
  }

  public async requestLoadMoreBlabs() {
    try {
      const { results, ...paging } = await getNextPage(this.blabsPaging!);
      this.blabs = this.blabs.concat(results);
      this.blabsPaging = paging;
    } catch (e) {}
  }

  public async requestLoadMoreMrktplaceListings() {
    try {
      const { results, ...paging } = await getNextPage(this.marketplaceListingsPaging!);
      this.marketplace = this.marketplace.concat(results);
      this.marketplaceListingsPaging = paging;
    } catch (e) {}
  }

  public async requestLoadMoreUsers() {
    if (this.text) {
      try {
        const { results, ...paging } = await getNextPage(this.usersPaging!);
        this.users = this.users.concat(results);
        this.usersPaging = paging;
      } catch (e) {}
    }
  }

  public async requestLoadMoreTags() {
    if (this.text) {
      try {
        const { results, ...paging } = await getNextPage(this.tagsPaging!);
        this.tags = this.tags.concat(results);
        this.tagsPaging = paging;
      } catch (e) {}
    }
  }

  public async searchWorlds() {
    if (this.text) {
      this.isSearchText = true;
      const { results, ...paging } = await getWorldListings(1, 5, 'story', { search: this.text });
      this.worlds = results;
      this.worldsPaging = paging;
    } else {
      this.isSearchText = false;
    }
  }

  public async searchChatrooms() {
    if (this.text) {
      this.isSearchText = true;
      const { results, ...paging } = await searchChatRooms(1, 5, { search: this.text });
      this.chatrooms = results;
      this.chatroomsPaging = paging;
    } else {
      this.isSearchText = false;
    }
  }

  public async searchSpaces() {
    if (this.text) {
      this.isSearchText = true;
      const { results, ...paging } = await getWorldListings(1, 5, 'socialspace', { search: this.text });
      this.spaces = results;
      this.spacesPaging = paging;
    } else {
      this.isSearchText = false;
    }
  }

  public async searchTags() {
    try {
      const { results, ...paging } = await getTags(1, 5, this.hashtext || this.text);
      this.tags = results;
      this.tagsPaging = paging;
    } catch (e) {}
  }

  public async search() {
    try {
      this.loading = true;
      if (this.text) sendAnalyticsEvent('Performed Search', { searchText: this.text });
      this.changeTagName();

      if (this.text?.includes('#')) {
        const textSplit = this.text.split('#');
        this.hashtext = textSplit[1];

        if (this.hashtext) {
          this.isSearchingTag = true;
          await Promise.all([
            this.searchCharTags(),
            this.searchBlabTags(),
            this.searchWorldTags(),
            this.searchSpacesTags(),
            this.searchChatroomTags(),
            this.searchMarketplaceTags(),
            this.searchTags(),
            this.searchUsers(),
          ]);
        }
      } else {
        this.hashtext = '';
        await Promise.all([
          this.searchTags(),
          this.searchcharacters(),
          this.searchBlabs(),
          this.searchWorlds(),
          this.searchSpaces(),
          this.searchChatrooms(),
          this.searchMarketplace(),
          this.searchUsers(),
        ]);
      }
    } catch {
      // TODO: handle error
    } finally {
      this.loading = false;
    }
  }

  public async searchBlabs() {
    if (this.text) {
      this.isSearchText = true;
      const { results, ...paging } = await getPosts(1, 5, { search: this.text });
      this.blabs = results;
      this.blabsPaging = paging;
    } else {
      this.isSearchText = false;
    }
  }

  public async searchMarketplace() {
    if (this.text) {
      this.isSearchText = true;
      const { results, ...paging } = await getMarketplaceListings(1, 5, { search: this.text });
      this.marketplace = results;
      this.marketplaceListingsPaging = paging;
    } else {
      this.isSearchText = false;
    }
  }

  public async searchUsers() {
    if (this.text) {
      this.isSearchText = true;
      const { results, ...paging } = await getUsers(1, 5, { search: this.hashtext || this.text });
      this.users = results;
      this.usersPaging = paging;
    } else {
      this.isSearchText = false;
    }
  }

  public async created() {
    const router = useRouter();
    const document = useDocument();
    document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

    const tIndex = indexOf(
      this.tabs,
      find(this.tabs, (tab) => tab.value === router.currentRoute.value.query.tab)
    );
    if (router.currentRoute.value.query.tab && tIndex !== -1) {
      this.tabIndex = tIndex;
      this.currentTab = String(router.currentRoute.value.query.tab);
    }
  }

  handleSearch(value: string) {
    this.text = value;

    if (this.text) {
      this.search();
    }
  }

  mounted() {
    this.handleSearch(String(this.getTerm));
  }
}
</script>
<style lang="sass" scoped>
.page
  padding: 0 !important
.content
  margin: 1rem
  padding-bottom: 3rem
</style>
